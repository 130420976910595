<template>
    <form  class="">
        <div class="row mb-4 g-3">
            <div class="col-md-12">
               <div v-for="(hour, hrIndex) in data.hours" :key="hrIndex">
                  <p class="mt-3 mb-2 text-uppercase">{{$filters.getWeekdayName(hrIndex)}}</p>
                  <div class="row mb-3 gy-3 gx-3">
                     <div class="col-6">
                        <label class="d-block">Open</label>
                        <flat-pickr
                           v-model="hour.open"
                           :config="timePickerConfig"
                           class="form-control flatpickr-input"
                        ></flat-pickr>
                     </div>
                     <div class="col-6">
                        <label class="d-block">Close</label>
                        <flat-pickr
                           v-model="hour.close"
                           :config="timePickerConfig"
                           class="form-control flatpickr-input"
                        ></flat-pickr>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-12">
                <div class="d-flex mt-3">
                    <b-button variant="primary"  class="w-100 me-3"
                    @click.prevent="update()"
                     type="button">Update</b-button>
                    <b-button variant="outline-primary" class="w-100"
                        @click.prevent="resetForm()"
                        type="button">Cancel</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import flatPickr from "vue-flatpickr-component";

export default {
    components:{
      flatPickr
    },
    props:{
      editItem:{
         type: Object,
         default: null
      },
    },
    data(){
        return{
            data: {
               hours:[
                  {open: "", close: ""},
                  {open: "", close: ""},
                  {open: "", close: ""},
                  {open: "", close: ""},
                  {open: "", close: ""},
                  {open: "", close: ""},
                  {open: "", close: ""}
               ]
            },
            timePickerConfig: {
               enableTime: true, // Enable time selection
               noCalendar: true, // Hide the calendar
               dateFormat: 'H:i:S', // Time format (hours:minutes)
            },
        }
    },
    methods:{
        update(){
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/stores/${this.editItem.id}/hours`,formData)
               .then((response) => {
                  this.$store.dispatch("changeLoaderValue", false)
                  if(response.data.success){
                     this.$emit('updatedData', response.data.data)
                     this.resetForm()
                  }
               })
        },
        resetForm(){
            this.$emit("closeMe")
        }
    },
    mounted(){
      this.data.hours = this.editItem.hours.map( i => {
         return { id: i.id, close: i.close, open: i.open }
      });
    }
}
</script>